import React from 'react';
import clsx from 'clsx';
import Logo from './Logo';
import SiteMenu from './SiteMenu';
import styles from './Topbar.module.scss';

const Topbar = ({ isDetailPage }) => (
  <div className={styles['topbar']}>
    {!isDetailPage && (
      <div
        className={styles['topbar__hero__cover']}
        style={{ backgroundImage: 'url(/media/banner-dark.jpg)' }}
      />
    )}
    <div
      className={clsx({
        [styles['topbar__hero__details']]: !isDetailPage,
        [styles['topbar__details']]: isDetailPage,
      })}>
      <Logo hero={!isDetailPage} />
      <span className={styles['subtitle']}>South Indian Vegetatrian Recepies </span>
      <SiteMenu />
    </div>

  </div>
);

export default Topbar;
