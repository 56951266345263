import { parse } from 'iso8601-duration';

export default function getDuration(duration) {
  if (!duration) {
    return '--';
  }

  const parsed = parse(duration);
  let out = '';

  if (parsed.days) out = `${parsed.days} day `;
  if (parsed.hours) out = `${out}${parsed.hours} hr `;
  if (parsed.minutes) out = `${out}${parsed.minutes} min `;

  return out;
}