import React from 'react';
import clsx from 'clsx';
import styles from './Logo.module.scss';

const Logo = ({ hero }) => (
  <a
    aria-label="Uma's kitchen"
    className={clsx(styles['logo'], { [styles['logo__hero']]: hero })}
    href="/">
    <svg viewBox="0 0 240.27 43.07">
      <path
        d="M107.9,155.94l-.82-2.22a18.43,18.43,0,0,0,5.16-2.43,14.38,14.38,0,0,0,3.82-3.64,6.83,6.83,0,0,0,1.4-3.92,2.86,2.86,0,0,0-.93-2.26,3.56,3.56,0,0,0-2.47-.82,10.09,10.09,0,0,0-4,1,19.68,19.68,0,0,0-4.35,2.69,27.47,27.47,0,0,0-4.11,4.16A30.75,30.75,0,0,0,98,154a29,29,0,0,0-2.17,5.9,26.63,26.63,0,0,0-.73,6.23c0,3.65.84,6.55,2.52,8.68a8.29,8.29,0,0,0,6.89,3.21,12.6,12.6,0,0,0,5.46-1.26,28.31,28.31,0,0,0,6.08-4.22l.47-.38.35.65a27.08,27.08,0,0,1-7,5.18,15.86,15.86,0,0,1-7.09,1.73A10.69,10.69,0,0,1,97,178.22a10.23,10.23,0,0,1-3.87-4.3,14.67,14.67,0,0,1-1.36-6.49,21.51,21.51,0,0,1,.79-5.63,29.88,29.88,0,0,1,2.36-5.93,40.43,40.43,0,0,1,3.71-5.82,33.48,33.48,0,0,1,5-5.34,24.27,24.27,0,0,1,5.52-3.65,11.94,11.94,0,0,1,5-1.29,4.63,4.63,0,0,1,3.33,1.19,4.22,4.22,0,0,1,1.24,3.21,9,9,0,0,1-1.49,4.7,16.18,16.18,0,0,1-4,4.36A15.34,15.34,0,0,1,107.9,155.94Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
      />
      <path
        d="M119.1,173.38a10.89,10.89,0,0,1,1.42-5.26,12.61,12.61,0,0,1,3.67-4.27,7.8,7.8,0,0,1,4.6-1.64,5.14,5.14,0,0,1,4,1.63,5.9,5.9,0,0,1,1.51,4.2,11.5,11.5,0,0,1-1.39,5.45,12.64,12.64,0,0,1-3.66,4.32,7.63,7.63,0,0,1-4.59,1.69,5.12,5.12,0,0,1-4-1.69A6.32,6.32,0,0,1,119.1,173.38Zm12.53-6.45a4.65,4.65,0,0,0-.86-2.9,2.66,2.66,0,0,0-2.21-1.14,4,4,0,0,0-2.36.79,7.87,7.87,0,0,0-2.07,2.31,17.75,17.75,0,0,0-1.74,4.1,15,15,0,0,0-.69,4.16,5.66,5.66,0,0,0,.85,3.34,2.81,2.81,0,0,0,2.43,1.18c1.7,0,3.2-1.19,4.49-3.55a17.87,17.87,0,0,0,1.58-4.07A16.88,16.88,0,0,0,131.63,166.93Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
      />
      <path
        d="M136.67,173.38a11,11,0,0,1,1.42-5.26,12.75,12.75,0,0,1,3.68-4.27,7.8,7.8,0,0,1,4.6-1.64,5.15,5.15,0,0,1,4,1.63,5.9,5.9,0,0,1,1.51,4.2,11.5,11.5,0,0,1-1.39,5.45,12.64,12.64,0,0,1-3.66,4.32,7.65,7.65,0,0,1-4.59,1.69,5.12,5.12,0,0,1-4-1.69A6.33,6.33,0,0,1,136.67,173.38Zm12.54-6.45a4.71,4.71,0,0,0-.86-2.9,2.67,2.67,0,0,0-2.21-1.14,4,4,0,0,0-2.36.79,7.87,7.87,0,0,0-2.07,2.31,17.75,17.75,0,0,0-1.74,4.1,15,15,0,0,0-.69,4.16,5.59,5.59,0,0,0,.85,3.34,2.81,2.81,0,0,0,2.43,1.18c1.7,0,3.2-1.19,4.48-3.55a17.43,17.43,0,0,0,1.59-4.07A16.88,16.88,0,0,0,149.21,166.93Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
      />
      <path
        d="M165.15,137.72l-8,34.66a21.16,21.16,0,0,1,1.61-3,25.54,25.54,0,0,1,2.14-2.85,16.29,16.29,0,0,1,2.32-2.27,10,10,0,0,1,6.15-2.35l-.53,2.35a7.11,7.11,0,0,0-1.32-.18q-3.45,0-6.44,3.49a51.31,51.31,0,0,0,2.55,5.8A27.09,27.09,0,0,0,165,175.7a6.06,6.06,0,0,0,1,1.31,1.52,1.52,0,0,0,1,.41c.74,0,1.68-.6,2.81-1.79l.23-.26.38.44q-3,3.57-4.3,3.57a2.37,2.37,0,0,1-1.7-.79,12.91,12.91,0,0,1-1.85-2.72,56.35,56.35,0,0,1-3-6.39,25.83,25.83,0,0,0-3.51,9.17l-1.91.61,7.86-40Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
      />
      <path
        d="M182.82,163.06l2.63-1.2v.76q0,2.08.12,3.87c.08,1.19.22,2.89.44,5.1.27,2.58.48,4.15.61,4.71s.38.85.71.85,1.13-.68,2.11-2.06a35.12,35.12,0,0,0,2.87-5l-.91-7,2.67-1.2,0,.73c.15,4.18.32,7.18.49,9,.06.47.12,1,.18,1.48s.1.92.13,1.21.07.63.13,1l.12.75c.16.65.41,1,.76,1q1.07,0,3.63-4.19Q202,169,202,166.29a3.49,3.49,0,0,0-1.41-3.11l2.23-2.05a4.48,4.48,0,0,1,1,3.05,11.79,11.79,0,0,1-1.14,4.65,33.88,33.88,0,0,1-3.4,5.83,37.24,37.24,0,0,1-2.58,3.26c-.86,1-1.56,1.46-2.11,1.46a1,1,0,0,1-1-.6,6.21,6.21,0,0,1-.5-2l-.65-5.24a31.91,31.91,0,0,1-2.95,4.86c-1.47,2-2.64,3-3.52,3a1,1,0,0,1-.91-.54,7.39,7.39,0,0,1-.53-2Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
      />
      <path
        d="M211.26,161.86l-2.57,9.64a15.37,15.37,0,0,0-.65,3.81,3.2,3.2,0,0,0,.32,1.52,1,1,0,0,0,.85.59c.61,0,1.57-.72,2.9-2.17l.47.41-.29.32a26,26,0,0,1-2.46,2.65,2.34,2.34,0,0,1-1.47.69,1.83,1.83,0,0,1-1.53-.92,3.92,3.92,0,0,1-.63-2.3,16.51,16.51,0,0,1,.35-2.78l1.84-10.26Zm.85-11.48a1.61,1.61,0,0,1,1.7,1.7,1.67,1.67,0,0,1-.5,1.23,1.63,1.63,0,0,1-1.2.49,1.71,1.71,0,1,1,0-3.42Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
      />
      <path
        d="M219.64,163.36l-2.22,9.75a12.31,12.31,0,0,0-.36,2.29,2.73,2.73,0,0,0,.5,1.7,1.58,1.58,0,0,0,1.32.64,2.51,2.51,0,0,0,1.45-.45,16.08,16.08,0,0,0,2-1.89l.35-.36.44.44a18.44,18.44,0,0,1-2.89,3,3.84,3.84,0,0,1-2.27.86,2.9,2.9,0,0,1-2.24-.92,3.46,3.46,0,0,1-.83-2.42,16.64,16.64,0,0,1,.44-3.22l1.9-9.4h-2.81l.2-1h2.82l1.64-7.91,2.81-1.11-2.05,9h4.74l-.2,1Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
      />
      <path
        d="M224.68,179.29l7.79-40.08,3.08-1.49-8.11,35.68a.83.83,0,0,0,.11-.26l.68-1.52a24.79,24.79,0,0,1,4.33-6.8c1.64-1.78,3.16-2.67,4.54-2.67a2.33,2.33,0,0,1,1.79.72,2.69,2.69,0,0,1,.67,1.92,7.86,7.86,0,0,1-.32,1.91l-.29,1.23L238,171.5l-.26,1.14a11.66,11.66,0,0,0-.44,2.7,3,3,0,0,0,.32,1.51,1,1,0,0,0,.85.57c.6,0,1.57-.72,2.9-2.17l.44.41-.26.32a25.58,25.58,0,0,1-2.48,2.65,2.36,2.36,0,0,1-1.45.69,1.85,1.85,0,0,1-1.55-1,4,4,0,0,1-.65-2.26,17.94,17.94,0,0,1,.38-2.78l1.12-6.21a12.7,12.7,0,0,0,.26-2.06,1.26,1.26,0,0,0-.32-.93,1.18,1.18,0,0,0-.88-.33,4,4,0,0,0-2.49,1.28,17.06,17.06,0,0,0-2.83,3.53,30.78,30.78,0,0,0-2.47,4.82,27.94,27.94,0,0,0-1.59,5.26Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
      />
      <path
        d="M277.8,179H275l.12-.7q.52-3.69,1.23-7.37t1.61-7.25l2.7-10.7c-.12.24-.21.44-.28.6l-.13.31-1.09,2.73q-1.08,2.64-2.87,6.4t-3.13,6.25q-5.65,10.32-10.23,10.32a3,3,0,0,1-2.38-1,3.91,3.91,0,0,1-.9-2.69,21.13,21.13,0,0,1,.65-3.83l4.68-20.6.77-3.37a22,22,0,0,0,.58-4.3c0-2-.58-3-1.76-3-1.36,0-2.5,1.3-3.39,3.89a53.85,53.85,0,0,0-2.41,9.91L256.47,153a26.6,26.6,0,0,1,3.13-9.43q2.17-3.57,4.75-3.57a3.06,3.06,0,0,1,2.78,1.77,10.09,10.09,0,0,1,1,4.94,46.08,46.08,0,0,1-1.44,9.43l-3.6,15.73-.29,1.35a9.55,9.55,0,0,0-.35,2.37,2.33,2.33,0,0,0,.51,1.57,1.6,1.6,0,0,0,1.27.6,4.71,4.71,0,0,0,3-1.58,24.43,24.43,0,0,0,3.63-4.76q2-3.18,4.57-8.54a124.82,124.82,0,0,0,5.86-14.5q1.5-4.37,2.08-6.89l2.55-1.05-5.77,25.51Q278.67,172.5,277.8,179Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="M296.25,178.68l-1.93.61,2.31-11.92.21-1a6.94,6.94,0,0,0,.18-1.4c0-.86-.35-1.29-1-1.29a3.79,3.79,0,0,0-2.34,1.3,18.81,18.81,0,0,0-2.9,3.77,32.27,32.27,0,0,0-1.72,3,31.78,31.78,0,0,0-1.32,3.12c-.4,1.11-.78,2.37-1.15,3.8l-1.82.61,3-16.23,2.81-1.2L288,172.5a28.53,28.53,0,0,1,4.5-7.46c1.69-1.94,3.21-2.92,4.55-2.92s2.2.84,2.2,2.5a18.37,18.37,0,0,1-.44,3l-1,4.37a41.54,41.54,0,0,1,4.8-7.69c1.31-1.5,2.61-2.24,3.9-2.24a2.24,2.24,0,0,1,1.7.64,2.58,2.58,0,0,1,.61,1.85,16,16,0,0,1-.59,3.34l-.9,3.6-.3,1.14a13.87,13.87,0,0,0-.41,2.7,3,3,0,0,0,.33,1.51.93.93,0,0,0,.82.57c.62,0,1.6-.72,2.93-2.17l.44.41-.27.32a25.81,25.81,0,0,1-2.47,2.65,2.38,2.38,0,0,1-1.48.69,1.84,1.84,0,0,1-1.54-.92,4,4,0,0,1-.63-2.3,16.81,16.81,0,0,1,.38-2.78l1.08-6.07.18-1.05a7.32,7.32,0,0,0,.17-1.41,1.14,1.14,0,0,0-.32-.85,1.2,1.2,0,0,0-.88-.32,2.56,2.56,0,0,0-1.42.54,8.06,8.06,0,0,0-1.65,1.66,28.2,28.2,0,0,0-2,2.93,41,41,0,0,0-2.37,4.64A44.09,44.09,0,0,0,296.25,178.68Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="M329.36,161.69l-2.75,10.72a11.77,11.77,0,0,0-.44,2.75,2.9,2.9,0,0,0,.38,1.53,1.08,1.08,0,0,0,.93.64c.57,0,1.51-.69,2.82-2.08l.41.41-.33.38a27.52,27.52,0,0,1-2.38,2.51,2.45,2.45,0,0,1-1.45.71,2,2,0,0,1-1.63-1,3.84,3.84,0,0,1-.69-2.27,28.23,28.23,0,0,1,.71-4.25l.23-1.11a21.58,21.58,0,0,1-2.9,4.83c-2,2.58-4,3.87-5.83,3.87a3.22,3.22,0,0,1-2.64-1.2,5,5,0,0,1-1-3.22,12.55,12.55,0,0,1,1.56-5.77,16.14,16.14,0,0,1,3.9-5,7.14,7.14,0,0,1,4.53-1.95,3.88,3.88,0,0,1,3.45,1.7l.33-1.11ZM326,165.44a3.34,3.34,0,0,0-1.19-1.75,3.07,3.07,0,0,0-1.92-.63,4.41,4.41,0,0,0-2.72,1.19,11.75,11.75,0,0,0-2.64,3.29,19.68,19.68,0,0,0-1.56,3.7,11.66,11.66,0,0,0-.63,3.34,4.18,4.18,0,0,0,.57,2.34,1.83,1.83,0,0,0,1.62.85,4.43,4.43,0,0,0,2.82-1.26,15.47,15.47,0,0,0,2.9-3.6,13.74,13.74,0,0,0,1.37-2.7,37.67,37.67,0,0,0,1.12-3.75Z"
        transform="translate(-91.79 -137.72)"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  </a>
);

export default Logo;
