import React from 'react';
import styles from './SiteMenu.module.scss';
import { useOnClickOutside } from '../../../hooks';
import Categories from '../Categories';

const MenuIcon = () => (
  <svg
    width="27.96"
    fill="currentColor"
    height="24.36"
    viewBox="0 0 27.96 24.36">
    <path d="M0,0V3.495H27.96V0ZM0,10.38v3.495H27.96V10.38ZM0,20.865V24.36H27.96V20.865Z" />
  </svg>
);

const SiteMenu = () => {
  const ref = React.useRef();
  const [categoryOpen, setCategoryOpen] = React.useState(false);
  const [, setMenuOpen] = React.useState(false);

  useOnClickOutside(ref, () => {
    setCategoryOpen(false);
    setMenuOpen(false);
  });

  return (
    <div ref={ref} className={styles['menu']}>
      {/* <span
        className={styles['menu__category']}
        onClick={() => setCategoryOpen(true)}
        tabIndex={0}
        aria-label="All Categories">
        All Categories
      </span>
      <span className={styles['menu__icon']} tabIndex={0} aria-label="Site Menu">
        <MenuIcon />
      </span>
      <Categories open={categoryOpen} onClose={() => setCategoryOpen(false)}/> */}
    </div>
  );
};

export default SiteMenu;
