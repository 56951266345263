import React from 'react';
import styles from './Footer.module.scss';

export default function Footer() {
  return (
    <footer className={styles['footer']}>
      <ul>
        <li>© Cook with Uma, All rights reserved.</li>
      </ul>
    </footer>
  );
}
